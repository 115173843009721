import React from 'react';
import Layout from 'components/Layout';
import Container from 'components/Container';
import Seo from "../../components/Seo";
import {Link} from "gatsby";

import banner from "../../images/feature-thrivable2.png";
import comps from "../../images/feature-thrivable-2.png";
import homepage from "../../images/thrivable/home.png";
import panelists from "../../images/thrivable/panelists.png";
import company from "../../images/thrivable/company.png";
import conditions from "../../images/thrivable/conditions.png";
import illos from "../../images/thrivable/illos.png";

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
        return () => observer.unobserve(domRef.current);
    }, []);
    return (
            <div
                    className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
                    ref={domRef}
            >
                {props.children}
            </div>
    );
}

class Thrivable extends React.Component {
    render() {
        return (
                <Layout pageName="thrivable">
                    <Seo
                            title={'Thrivable'}
                    />
                    <Container>
                        <section>
                            <div className="extra-large-wrapper mt-16 sm:mt-36">
                                <h1 className="font-serif text-left text-4xl sm:text-9xl leading-none sm:-mt-4">Thrivable</h1>
                                <div className="sm:flex justify-between mt-16 sm:mt-24">
                                    <div className="flex">
                                        <div className="">
                                            <h3 className="font-mono italic text-sm featured-color">Role</h3>
                                            <ul className="w-auto mt-2 inline-flex flex-col items-start">
                                                <li className="border rounded-full py-1 px-4 w-auto font-mono italic text-sm">Brand Identity</li>
                                                <li className="border rounded-full py-1 px-4 mt-3 font-mono italic text-sm">Website & App Design</li>
                                            </ul>
                                        </div>
                                        <div className="ml-24">
                                            <h3 className="font-mono italic text-sm featured-color">Date</h3>
                                            <h4 className="mt-2">2020 – 2022</h4>
                                        </div>
                                    </div>
                                    <div className="mt-8 sm:mt-0 sm:w-2/5">
                                        <p className="leading-normal">Thrivable is a SAAS platform helping connect patients and companies in the healthcare industry. The online app is a market research platform that makes it easy to create surveys, interviews and focus groups for patients. I worked in collaboration with the Founder & CEO to lead the initial brand design efforts as well as design the marketing website and application.</p>
                                        <a href="https://thrivable.app/" target="_blank" rel="noreferrer" className="mt-8 block featured-color hover:underline">Launch Website -></a>
                                    </div>
                                </div>
                                <FadeInSection>
                                <div className="mt-16">
                                    <img src={banner} alt="project images"/>
                                </div>
                                </FadeInSection>
                                <div className="mt-16 sm:mt-32 grid gap-8 lg:gap-16 sm:grid-cols-2 content-start">
                                    <FadeInSection>
                                    <img src={homepage} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                    </FadeInSection>
                                    <FadeInSection>
                                    <img src={panelists} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                    </FadeInSection>
                                    <FadeInSection>
                                    <img src={company} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                    </FadeInSection>
                                    <FadeInSection>
                                    <img src={conditions} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                    </FadeInSection>
                                </div>
                                <div className="mt-16 sm:mt-32">
                                    <FadeInSection>
                                    <img src={comps} alt="project images"/>
                                    </FadeInSection>
                                    <FadeInSection>
                                    <img src={illos} alt="project images" className="mt-16 sm:mt-32"/>
                                    </FadeInSection>
                                </div>
                            </div>
                        </section>
                        <section>
                            <FadeInSection>
                                <div className="extra-large-wrapper text-center relative mt-16 sm:mt-32 sm:mb-16">
                                    <Link to="/work/housepartner" className="text-3xl sm:text-6xl font-serif border-b-2 border-white hover:border-black">
                                        next project — HousePartner
                                    </Link>
                                </div>
                            </FadeInSection>
                        </section>
                    </Container>
                </Layout>
        );
    }
}

export default Thrivable;
